.pagination {
  justify-content: flex-start !important;
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

.page-item {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.page-link {
  background-color: #f9f9f9;
}

.page-item .page-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 7px 0 0;
  min-height: 32px !important;
  min-width: 32px !important;
  max-height: 32px !important;
  max-width: 32px !important;
  border-radius: 4px;
  font-weight: 400;
  text-align: center;
  font-size: 1rem;
  border: none !important;
  box-shadow: none !important;
  color: #84858d;
  text-decoration: none;
}

.page-item a.page-link:hover {
  background-color: var(--color-primary);
  color: #ffffff;
}

.page-item.active a.page-link {
  font-weight: 400;
  background-color: var(--color-primary);
  color: #ffffff !important;
}

.page-item.disabled .page-link {
  color: var(--color-primary);
  pointer-events: none;
  cursor: auto;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.pagination {
  & > li:first-child .page-link {
    max-width: initial !important;
    &:hover {
      background-color: #fff !important;
      color: #84858d;
    }
    display: flex;
    align-items: center;
    span {
      display: none;
    }

    &::after {
      content: url('../images/prev-arrow.svg') !important;
      width: 20px;
      height: 20px;
    }
  }
  & > li:last-child .page-link {
    max-width: initial !important;
    &:hover {
      background-color: #fff !important;
      color: #84858d;
    }
    display: flex;
    align-items: center;
    span {
      display: none;
    }
    &::before {
      content: url('../images/next-arrow.svg') !important;
      width: 20px;
      height: 20px;
    }
  }
}
